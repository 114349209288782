import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Article from '../components/article';
import ArticlesList from '../components/articles-list';
import { textContainer } from '../components/text-container.module.css';
import { Seo } from '../components/seo';

// markup
const NotFoundPage = ({ data }) => {
  const pageTitle = `That page doesn't exist`;
  return (
    <Layout pageTitle={pageTitle}>
      <Seo pageTitle={pageTitle} index={false} follow={false} />
      <Article>
        <h1 className="fancy">
          <span style={{ fontSize: '1em', textShadow: 'none' }}>🕵️</span>
          {' ' + pageTitle}
        </h1>
        <p>
          Experience is what you get when you don't get what you want. You
          wanted a page I can't find (sorry about that 😢), so what about the
          experience of discovering some other articles instead?
        </p>
      </Article>
      <div className={textContainer} style={{ paddingTop: '0' }}>
        <h2 className="fancy" style={{ marginBottom: '0' }}>
          Start Here!
        </h2>
        <ArticlesList
          articles={data.startHereArticles.edges}
          HeadingElement="h3"
          descriptionField="startHereDescription"
        />
      </div>
      <div className={textContainer}>
        <h2 className="fancy" style={{ marginBottom: '0' }}>
          Or Read the Latest
        </h2>
        <ArticlesList
          articles={data.latestArticles.edges}
          HeadingElement="h3"
        />
        <a href="/articles/" className="button" style={{ marginTop: '1em' }}>
          See more articles
        </a>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundPageQuery {
    startHereArticles: allMdx(
      filter: { frontmatter: { tags: { eq: "start here" } } }
      sort: { fields: [frontmatter___startHereOrder], order: ASC }
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            title
            tags
            date
            startHereDescription
            startHereOrder
          }
        }
      }
    }
    latestArticles: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            title
            tags
            date
            description
          }
        }
      }
    }
  }
`;
